@use 'node_modules/@amzn/awsui-design-tokens/polaris' as awsui;

.awsui-icon.awsui-icon-size-normal.awsui-icon-variant-normal {
  visibility: hidden;
}

h5.tools-h5 {
  color: gray;
  margin-top: 2%;
  margin-left: 5%;
}