@use 'node_modules/@amzn/awsui-design-tokens/polaris' as awsui;

#app-header {
  padding: 0 1%;
  margin: 0;
  height: 6rem;
  background: #232f3e;
}

.awsui ul.menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 6rem;

  & li {
    display: inline-block;
    margin: 0;
    padding: 0;

    &.logo {
      width: 20%;
      height: auto;

      a {
        &:hover {
          cursor: default;
        }
      }

      img {
        height: 2.5rem;
        width: 18rem;
      }
    }

    &.banner {
      width: 70%;
      height: auto;

      a {
        &:hover {
          cursor: default;
        }
      }
    }

    &.dark-mode-toggle {
      width: 5%;
      height: auto;

      a {
        &:hover {
          cursor: default;
        }
      }
    }

    &.user-photo {
      width: 5%;
      height: 3.5rem;

      a {
        &:hover {
          cursor: default;
        }
      }

      img {
        height: 4rem;
        width: 3rem;
        display:block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}